import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import user from './modules/user'
import alldemand from './modules/alldemand'
import publish from './modules/publish'
import sort from './modules/sort'
import detail from './modules/detail'
import contactus from './modules/contactus'
import bidd from './modules/bidd'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    alldemand,
    publish,
    sort,
    detail,
    contactus,
    bidd
  },
  getters
})
