import { GetBaoHanSort, GetBaoxianSort } from '@/api/sort'
const state = {

}
const mutations = {

}
const actions = {
  // 获取保函需求的分类
  async getBaohanSort ({ commit }, item) {
    const res = await GetBaoHanSort(item)
    return res.data
  },
  // 获取保险需求的分类
  async getBaoxianSort ({ commit }, item) {
    const res = await GetBaoxianSort(item)
    return res.data
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
