import { userphone, yanPhone, getCode, getUserInfo, getUser, getUserdetail, AddUser, GetUserBaoHan, GetUserbaoxian, putUser, delUser, GetUserBidd, UserIsBorker, borkerImg } from '@/api/user'

const state = {

}
const mutations = {

}
const actions = {
  // 获取code的链接
  async getcode ({ commit }) {
    const res = await getCode()
    // console.log(res.data)
    return res.data.result
  },
  // 获取用户信息
  async getuserinfo ({ commit }, item) {
    const res = await getUserInfo(item)
    return res.data
  },
  // 查看用户是否存在
  async lookuser ({ commit }, item) {
    const res = await getUser(item)
    return res.data
  },
  // 更新用户信息
  async putuser ({ commit }, item) {
    const res = await putUser(item)
    return res
  },
  // 创建用户
  async adduser ({ commit }, item) {
    const res = await AddUser(item)
    return res.data
  },
  // 删除用户
  async deluser ({ commit }, item) {
    const res = await delUser(item)
    return res
  },
  // 获取用户的待审核需求
  async getuserbaoHan (context, item) {
    const res = await GetUserBaoHan(item)
    return res.data.results
  },
  async getuserbaoxian (context, item) {
    const res = await GetUserbaoxian(item)
    return res.data.results
  },
  // 获取用户竞标需求
  async getuserbidd ({ commit }, item) {
    const res = await GetUserBidd(item)
    return res.data
  },
  // 判断用户是否为经纪人
  async getBorker ({ commit }, item) {
    const res = await UserIsBorker(item)
    return res.data
  },
  // 上传认证经纪人的图片信息
  async addborker ({ commit }, item) {
    const res = await borkerImg(item)
    return res
  },
  // 查询用户详情
  async getuserdetail ({ commit }, item) {
    const res = await getUserdetail(item)
    return res.data
  },
  // 获取短信验证码
  async yanphone ({ commit }, item) {
    const res = await yanPhone(item)
    return res
  },
  // 增加用户手机号
  async userphone ({ commit }, item) {
    const res = await userphone(item)
    return res
  }
  // 更新认证经纪人的图片信息
  // async updateborker ({ commit }, item) {
  //   const res = await updateBorker(item)
  //   return res
  // }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
