import { publishBaohan, publishBaoxian, otherReq, GetBaohandraft, AddBaohandraft } from '@/api/publish'
const state = {

}
const mutations = {

}
const actions = {
  // 发布保函需求
  async addBaohan ({ commit }, item) {
    const res = await publishBaohan(item)
    return res
  },
  // 发布保险需求
  async addbaoxian ({ commit }, item) {
    const res = await publishBaoxian(item)
    return res
  },
  // 其它需求留言
  async otherreq ({ commit }, item) {
    const res = await otherReq(item)
    return res
  },
  // 保函功能的草稿箱
  async getbaohandraft ({ commit }, item) {
    const res = await GetBaohandraft(item)
    return res.data
  },
  // 添加保函进草稿箱
  async addbaohandraft ({ commit }, item) {
    await AddBaohandraft(item)
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
