import request from '@/utils/request'

// 获取code的链接
export const getCode = () => request.get('/users/getcode/')

// 获取用户的微信信息
export const getUserInfo = (item) => request.get('/users/getinfo/', { params: item })

// 查询用户信息
export const getUser = (data) => request.get('/users/user/', { params: data })
export const getUserdetail = (id) => request.get(`/users/user/${id}/`)
// 更新用户信息
export const putUser = (data) => request.put(`/users/user/${data.id}/`, data)

// 删除用户信息
export const delUser = (data) => request.delete(`/users/user/${data.id}/`, { params: { data } })

// 根据用户信息发请求创建用户
export const AddUser = (item) => request.post('/users/user/', item)

// 获取用户的待审核需求
// 获取用户的保函需求
export const GetUserBaoHan = (data) => request.get('/guarantees/guarantee/?page=1&size=999999', { params: data })
// 获取用户的保险需求
export const GetUserbaoxian = (data) => request.get('/guarantees/insure/?page=1&size=999999', { params: data })

// 获取用户的竞标需求数据
export const GetUserBidd = (data) => request.get('/contends/biddreq/?page=1&size=999999', { params: data })

// 判断用户是否认证为经纪人
export const UserIsBorker = (data) => request.get('/users/broker/', { params: data })

// 上传认证经纪人的图片
export const borkerImg = (data) => request.post('/users/broker/', data)

// 更新认证经纪人的图片信息
// export const updateBorker = (data) => request.put('/users/broker/', data)

// 获取短信验证码
export const yanPhone = (data) => request.get('/sms_codes/', { params: data })

// 新增用户得手机号
export const userphone = (data) => request.post('/users/updatemobile/', data)
