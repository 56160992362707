import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // 打包后会出现空白，没重定向
  {
    path: '/',
    redirect: '/index',
    component: () => import('@/views/LayoutPage.vue'),
    children: [{
      path: '/index',
      name: 'index',
      component: () => import('@/views/layout/IndexPage.vue'),
      meta: {
        keepAlive: true,
        scrollTop: 0
      }
    },
    { path: '/sort', component: () => import('@/views/layout/SortPage.vue') },
    { path: '/user', component: () => import('@/views/layout/UserPage.vue') },
    {
      path: '/bankbao',
      component: () => import('@/views/sort/bankbao-page.vue'),
      meta: {
        keepAlive: true,
        scrollTop: 0
      }
    },
    {
      path: '/guarbao',
      component: () => import('@/views/sort/guarbao-page.vue'),
      meta: {
        keepAlive: true,
        scrollTop: 0
      }
    },
    {
      path: '/insurbao',
      component: () => import('@/views/sort/insurbao-page.vue'),
      meta: {
        keepAlive: true,
        scrollTop: 0
      }
    },
    {
      path: '/groupbao',
      component: () => import('@/views/sort/groupbao-page.vue'),
      meta: {
        keepAlive: true,
        scrollTop: 0
      }
    },
    {
      path: '/securebao',
      component: () => import('@/views/sort/securebao-page.vue'),
      meta: {
        keepAlive: true,
        scrollTop: 0
      }
    },
    {
      path: '/risksbao',
      component: () => import('@/views/sort/risksbao-page.vue'),
      meta: {
        keepAlive: true,
        scrollTop: 0
      }
    }
    ]
  },
  {
    path: '/layoutsort',
    component: () => import('@/views/requirehall/LayoutSort.vue'),
    children: [
      { path: '/subbank', component: () => import('@/views/requirehall/sub-bank.vue') },
      { path: '/subguar', component: () => import('@/views/requirehall/sub-guar.vue') },
      { path: '/subinsur', component: () => import('@/views/requirehall/sub-insur.vue') },
      { path: '/subrisks', component: () => import('@/views/requirehall/sub-risks.vue') },
      { path: '/subgroup', component: () => import('@/views/requirehall/sub-group.vue') },
      { path: '/subsecure', component: () => import('@/views/requirehall/sub-secure.vue') }
    ]
  },
  {
    path: '/selectsort',
    component: () => import('@/views/requirehall/select-sort.vue')
  },
  {
    path: '/userset',
    component: () => import('@/views/user/userset-page.vue')
  },
  {
    path: '/userabout',
    component: () => import('@/views/user/userabout-page.vue')
  },
  {
    path: '/userserve',
    component: () => import('@/views/user/userserve-page.vue')
  },
  {
    path: '/userout',
    component: () => import('@/views/user/userout-page.vue')
  },
  {
    path: '/userpassword',
    component: () => import('@/views/user/userpassword-page.vue')
  },
  {
    path: '/userphone',
    component: () => import('@/views/user/userphone-page.vue')
  },
  {
    path: '/useritem',
    component: () => import('@/views/user/useritem-page.vue')
  },
  {
    path: '/userbroker',
    component: () => import('@/views/user/userbroker-page.vue')
  },
  {
    path: '/subserve',
    component: () => import('@/views/requirehall/sub-serve.vue')
  },
  {
    path: '/bidprice',
    component: () => import('@/views/bidd/bidding-page')
  },
  {
    path: '/mybidd',
    component: () => import('@/views/bidd/mybidd-page')
  },
  {
    path: '/bankbidddetail',
    component: () => import('@/views/bidd/bidd-detail/bankbidd-detail.vue')
  },
  {
    path: '/insurbidddetail',
    component: () => import('@/views/bidd/bidd-detail/insurbidd-detail.vue')
  },
  {
    path: '/guarbidddetail',
    component: () => import('@/views/bidd/bidd-detail/guarbidd-detail.vue')
  },
  {
    path: '/guarbidd',
    component: () => import('@/views/bidd/guarbidd-page')
  },
  {
    path: '/baoxianguar',
    component: () => import('@/views/bidd/baoxian-guar')
  },
  {
    path: '/insurbidd',
    component: () => import('@/views/bidd/insurbidd-page')
  },
  // 登录路由
  {
    path: '/home',
    component: () => import('@/views/user/HomePage.vue')
  },
  // 详情页
  {
    path: '/bankdetail',
    component: () => import('@/views/detail/bank-detail.vue')
  },
  // 建工团意详情页
  {
    path: '/groupdetail',
    component: () => import('@/views/detail/group-detail.vue')
  },
  // 建工一切险详情页
  {
    path: '/risksdetail',
    component: () => import('@/views/detail/risks-detail.vue')
  },
  // 建工安责险
  {
    path: '/securedetail',
    component: () => import('@/views/detail/secure-detail.vue')
  },
  // 待审核页面
  {
    path: '/wrait',
    component: () => import('@/views/Publishreq/wrait-audit.vue'),
    meta: {
      keepAlive: true,
      scrollTop: 0
    }
  },
  // 已发布
  {
    path: '/finish',
    component: () => import('@/views/Publishreq/finish-publish.vue'),
    meta: {
      keepAlive: true,
      scrollTop: 0
    }
  },
  // 竞标中
  {
    path: '/bidding',
    component: () => import('@/views/Publishreq/bidding-publish.vue'),
    meta: {
      keepAlive: true,
      scrollTop: 0
    }
  },
  // 已完成
  {
    path: '/done',
    component: () => import('@/views/Publishreq/done-publish.vue'),
    meta: {
      keepAlive: true,
      scrollTop: 0
    }
  },

  // 已驳回
  {
    path: '/reject',
    component: () => import('@/views/Publishreq/reject-publish.vue'),
    meta: {
      keepAlive: true,
      scrollTop: 0
    }
  },

  // 竞标的需求
  {
    path: '/biddreq',
    component: () => import('@/views/biddReq/bidding-req.vue'),
    meta: {
      keepAlive: true,
      scrollTop: 0
    }
  },
  // 中标的需求
  {
    path: '/biddcenter',
    component: () => import('@/views/biddReq/biddcenter-req.vue'),
    meta: {
      keepAlive: true,
      scrollTop: 0
    }
  },
  // 丢标的需求
  {
    path: '/biddlose',
    component: () => import('@/views/biddReq/biddlose-req.vue'),
    meta: {
      keepAlive: true,
      scrollTop: 0
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
