import { GetContact } from '@/api/contactus'
const state = {
  contactItem: [] // 客服信息
}
const mutations = {
  setcontactItem (state, item) {
    state.contactItem = item
  }
}
const actions = {
  // 获取客服信息
  async getContact ({ commit }) {
    const res = await GetContact()
    commit('setcontactItem', res.data)
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
