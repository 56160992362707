import request from '@/utils/request'

// 发布保函需求
export const publishBaohan = (data) => request({
  method: 'post',
  url: '/guarantees/guarantee/',
  data,
  headers: { 'Content-Type': 'multipart/form-data' }
})

// 获取保函功能的草稿箱
export const GetBaohandraft = (data) => request.get('/guarantees/drafts/', { params: data })
// 添加保函进草稿箱
export const AddBaohandraft = (data) => request.post('/guarantees/drafts/', data)

// 发布保险需求
export const publishBaoxian = (data) => request({
  method: 'post',
  url: '/guarantees/insure/',
  data,
  headers: { 'Content-Type': 'multipart/form-data' }
})

// 其它需求留言
export const otherReq = (data) => request({
  method: 'post',
  url: '/users/requirement/',
  data,
  headers: { Accept: 'application/json' }
})
