<template>
  <div id="app">
    <!-- 一级路由出口 -->
    <keep-alive >
      <!-- 在首页路由那里加了个meta,对象信息 ，该路由需要被缓存的信息或标识-->
      <!-- 通过$route.meta.keepAlive来判断该路由是否需缓存 -->
      <router-view v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"/>

  </div>
</template>

<script>

export default {

}
</script>

<style lang="less">

</style>
