import axios from 'axios'
import { Toast } from 'vant'
// import Vue from 'vue'
// 创建axios实例
const request = axios.create({
  baseURL: 'https://bang.taoyuer.com',
  timeout: 5000
})

// 添加请求拦截器
request.interceptors.request.use(function (config) {
  // Vue.prototype.$httpCounter++
  // if (Vue.prototype.$httpCounter === 1) {
  Toast.loading({
    message: '加载中...',
    forbidClick: true,
    duration: 5000,
    overlay: true
  })
  // }
  // 在发送请求之前做些什么
  return config
}, function (error) {
  // 关闭提示
  Toast.clear()
  Toast.fail('网络异常，请稍后再试')
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
request.interceptors.response.use(function (response) {
  // Vue.prototype.$httpCounter--
  // 关闭提示
  // if (Vue.prototype.$httpCounter === 0) {
  //   setTimeout(() => {
  //     Toast.clear()
  //   }, 2000)
  // }
  // 对响应数据做点什么
  // console.log(response, 88888)

  Toast.clear()

  return response
}, function (error) {
  // 关闭提示
  Toast.clear()
  Toast.fail('服务器请求失败，请联系管理员')
  return Promise.reject(error)
})

export default request
