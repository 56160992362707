import { Tabbar, Dialog, Cascader, Button, Picker, Uploader, Calendar, Area, DatetimePicker, Form, Radio, Toast, RadioGroup, TabbarItem, NavBar, Icon, Tab, Tabs, List, Cell, Popup, Step, Steps, Field, Checkbox } from 'vant'
import Vue from 'vue'
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(NavBar)
Vue.use(Icon)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(List)
Vue.use(Cell)
Vue.use(Popup)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Checkbox)
Vue.use(Field)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Toast)
Vue.use(Form)
Vue.use(Picker)
Vue.use(DatetimePicker)
Vue.use(Area)
Vue.use(Calendar)
Vue.use(Uploader)
Vue.use(Button)
Vue.use(Dialog)
Vue.use(Cascader)
Vue.prototype.$toast = Toast
