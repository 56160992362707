<template>
  <div>
       <!-- 导航栏 -->
    <van-nav-bar
    left-text="返回"
    left-arrow
    :title="title"
    :right-text="rightText"
    @click-left="onClickLeft"
    @click-right="onClickRight"
    fixed
   > </van-nav-bar>
    <!-- 导航栏 -->
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    rightText: {
      type: String,
      default: ''
    }
  },
  methods: {
    // 点击左边
    onClickLeft () {
      this.$router.back()
    },
    // 点击右边
    onClickRight () {
      this.$emit('upload')
    }
  }
}
</script>

<style lang="less" scoped>
//导航栏区域
/deep/.van-nav-bar__left{
  font-size: 18px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  .van-nav-bar__arrow{
    font-size: 16px;
  }
  .van-icon{
    font-size: 19px;
    color: #858585 !important;
  }
  .van-nav-bar__text{
    font-size: 16px;
    color: #858585 !important;
  }
}
/deep/ .van-nav-bar--fixed{
  z-index: 999 !important;
}
/deep/.van-nav-bar__right{
  font-size: 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  .van-nav-bar__text {
    color: #000000;
  }
}
</style>
