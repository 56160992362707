import request from '@/utils/request'

// 获取保函需求的竞价信息
export const GetBaoHanBidd = (data) => request.get('/contends/contends?page=1&size=999999', { params: data })

// 我要报价
export const AddBidd = (data) => request.post('/contends/contends/', data)

// 查询当前用户有没有报价
export const LookUserBidd = (data) => request.get('/contends/contends/', { params: data })

// 更新当前用户的报价金额
export const PutUserBidd = (data) => request.put(`/contends/contends/${data.biddId}/`, data)

// 该发布人获取其他用户的报价详情信息
export const UserBiddDetail = (id) => request.get(`/contends/contends/${id}/`)
