import { GetBaoHanBidd, AddBidd, LookUserBidd, PutUserBidd, UserBiddDetail } from '@/api/bidd'
const state = {

}
const mutations = {

}
const actions = {
  // 获取保函的竞价信息
  async getbaohanbidd ({ commit }, item) {
    const res = await GetBaoHanBidd(item)
    return res.data.results
  },
  // 我的报价
  async mybidd ({ commit }, item) {
    const res = await AddBidd(item)
    return res
  },
  // 查询当前用户有没有报价
  async lookuserbidd ({ commit }, item) {
    const res = await LookUserBidd(item)
    return res.data
  },
  // 更新当前的用户报价金额
  async putsuerbidd ({ commit }, item) {
    const res = await PutUserBidd(item)
    return res
  },
  // 获取其他用户报价详情
  async userbidddetail ({ commit }, id) {
    const res = await UserBiddDetail(id)
    return res.data
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
