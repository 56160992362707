import { GetBaoHanDetail, GetBaoXianDetail } from '@/api/detail'
const state = {

}
const mutations = {

}
const actions = {
  // 获取保函的详情
  async getBaohandetail ({ commit }, item) {
    const res = await GetBaoHanDetail(item)
    return res.data
  },
  // 获取保险的详情
  async getbaoxiandetail ({ commit }, item) {
    const res = await GetBaoXianDetail(item)
    return res.data
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
