import { GetAllDemand, GetBaoHan, Getbaoxian, GetCity } from '@/api/alldemand'
const state = {
  alldemand: [], // 全部的需求
  baohandemand: [], // 保函的需求
  baoxiandemand: [], // 保险需求
  cityItem: {}// 筛选的省市区
}
const mutations = {
  // 全部的需求
  setalldemand (state, item) {
    state.alldemand = item
  },
  // 保函的需求
  setbaohandemand (state, item) {
    state.baohandemand = item
  },
  // 保险的需求
  setbaoxiandemand (state, item) {
    state.baoxiandemand = item
  },
  // 筛选省市区
  setcityItem (state, item) {
    state.cityItem = item
  }

}
const actions = {
  // 获取全部的需求
  async getAll ({ commit }) {
    const res = await GetAllDemand()
    commit('setalldemand', res.data)
    return res.data
  },
  // 获取保函需求
  async getbaohan ({ commit }, item) {
    const res = await GetBaoHan(item)
    commit('setbaohandemand', res.data.results)
    // console.log(res)
    return res.data.results
  },
  // 获取保险需求
  async getbaoxian ({ commit }, item) {
    const res = await Getbaoxian(item)
    commit('setbaoxiandemand', res.data.results)
    // console.log(res)
    return res.data.results
  },
  // 获取省市区的筛选信息
  async getcity ({ commit }) {
    const res = await GetCity()
    console.log(res)
    commit('setcityItem', res.data)
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
