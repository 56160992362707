import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/vant-ui'
// 记得引入 清除默认样式文件
import '@/assets/style/css/base.css'
// 引入字体图标
import '@/assets/icon/iconfont.css'
import HeadNav from '@/components/head-nav.vue'
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
Vue.component('HeadNav', HeadNav)
Vue.config.productionTip = false
Vue.prototype.$httpCounter = 0 // loading相关
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
