
import request from '@/utils/request'

// 获取所有的需求
export const GetAllDemand = () => request.get('/guarantees/all/')

// 获取保函需求
export const GetBaoHan = (data) => request.get('/guarantees/guarantee/?status__in=2,4&page=1&size=999999', { params: data })

// 获取保险需求
export const Getbaoxian = (data) => request.get('/guarantees/insure/?status__in=2,4&page=1&size=999999', { params: data })

// 获取省市区的数据
export const GetCity = () => request.get('/guarantees/region/')
